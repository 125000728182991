/******************************************
 *  Author : Nikhil Unni
 *  Created On : Wed Oct 27 2021
 *  File : ThunkAction.js
 *******************************************/

import { apiResponseStatuses } from '../../config/constants/ApiServiceConstants';
import * as impossibleVideoActions from './Actions';
import { impossibleVideoService } from '../../services/api';
import Images from '../../config/Images'


export const getImpossibleVideoInfo = (args) => async (dispatch) => {

  try {
    const videoResponse = await impossibleVideoService.getVideoInfo(args);

    if (videoResponse.data.is_success) {
      
      dispatch(impossibleVideoActions.impossibleVideoData(
        videoResponse.data.is_success,
        videoResponse.data.data.videoUrl,
        videoResponse.data.data.restaurantName,
        'success'
      ));
    }
    else {
      
      dispatch(impossibleVideoActions.impossibleVideoData(
        videoResponse.data.is_success,
        '',
        '',
        'error'
      ))
    }

    // then store the user information to redux store

  } catch (error) {
    // eslint-disable-next-line no-console
    const { code, message } = error;
    let errorMessage = message;
    if (code === 'UserNotConfirmedException') {
      errorMessage = 'User is not verified'
    }
  }
};

