/******************************************

 *  Author : Nikhil Unni

 *  Created On : Wed Oct 27 2021

 *  File : ThunkAction.js

 *******************************************/


import * as companyForestActions from './Actions';

import { companyForestVideoService } from '../../services/api';



export const getCompanyForestInfo = (args) => async (dispatch) => {


  try {


    const companyForestResponse = await companyForestVideoService.getCompanyForestInfo(args);


    if (companyForestResponse.data.data && companyForestResponse.data.status === 200 && companyForestResponse.data.data.length) {

      console.log('success');

      dispatch(companyForestActions.companyForestData(

        true,

        true,

        false,

        companyForestResponse.data.data[0],

        'success'

      ));

    }

    else {

      console.log('failure');

      dispatch(companyForestActions.companyForestData(

        false,

        false,

        false,

        {},

        'error'

      ))

    }


    // then store the user information to redux store


  } catch (error) {

    // eslint-disable-next-line no-console

    const { code, message } = error;

    let errorMessage = message;

    if (code === 'UserNotConfirmedException') {

      errorMessage = 'User is not verified';

    }

  }

};

