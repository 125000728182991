/******************************************

 *  Author : Nikhil Unni

 *  Created On : Wed Oct 27 2021

 *  File : Action.js

 *******************************************/

import {

    GET_COMPANY_FOREST_INFO
  
  } from './Types';
  
  
  
  export function companyForestData(
  
    isValid,
  
    isMpUser,
  
    isCfdUser,
  
    mpUserData,
  
    loadingStatus
  
  ) {
  
    return {
  
      type: GET_COMPANY_FOREST_INFO,
  
      isValid,
  
      isMpUser,
  
      isCfdUser,
  
      mpUserData,
  
      loadingStatus
  
    };
  
  }