/******************************************
 *  Author : Nikhil Unni
 *  Created On : Wed Oct 27 2021
 *  File : Action.js
 *******************************************/
import {
  GET_PATH_INFO_IV
} from './Types';


export function impossibleVideoData(
  isValid,
  videoUrl,
  restaurantName,
  loadingStatus
) {

  return {
    type: GET_PATH_INFO_IV,
    isValid,
    videoUrl,
    restaurantName,
    loadingStatus
  };
}